var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signUpForm" }, [
    _c("div", { staticClass: "center" }, [
      _c("h1", [_vm._v("確認コード")]),
      _vm._m(0),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("h2", [_vm._v("ユーザー: " + _vm._s(_vm.username))]),
          _c(
            "div",
            [
              _c("text-field", {
                attrs: { label: "確認コード", maxlength: 6 },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
              _c("text-field", {
                attrs: { label: "新パスワード", type: "password" },
                model: {
                  value: _vm.newPassword,
                  callback: function ($$v) {
                    _vm.newPassword = $$v
                  },
                  expression: "newPassword",
                },
              }),
              _c("div", { staticClass: "attention" }, [
                _vm._v(
                  "※ パスワードは7文字以上かつ大文字英字が1つ以上必須です"
                ),
              ]),
              _c("button-default", [_vm._v("パスワード設定")]),
            ],
            1
          ),
          _vm.message ? _c("div", [_vm._v(_vm._s(_vm.message))]) : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(" 登録メールアドレスに確認コードを送信しました。"),
      _c("br"),
      _vm._v(" 確認コードを入力してパスワードの再設定を完了しましょう。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }