import { render, staticRenderFns } from "./ResetPasswordPage.vue?vue&type=template&id=2ecc7476&scoped=true"
import script from "./ResetPasswordPage.vue?vue&type=script&lang=ts"
export * from "./ResetPasswordPage.vue?vue&type=script&lang=ts"
import style0 from "./ResetPasswordPage.vue?vue&type=style&index=0&id=2ecc7476&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ecc7476",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/tty215/develop/indent/nola/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ecc7476')) {
      api.createRecord('2ecc7476', component.options)
    } else {
      api.reload('2ecc7476', component.options)
    }
    module.hot.accept("./ResetPasswordPage.vue?vue&type=template&id=2ecc7476&scoped=true", function () {
      api.rerender('2ecc7476', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/auth/ResetPasswordPage.vue"
export default component.exports