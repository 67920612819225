
import Vue from "vue";
import { Auth } from "aws-amplify";

export default Vue.extend({
  name: "newPassword",
  props: {
    username: String,
  },
  data() {
    return {
      newPassword: "",
    };
  },
  methods: {
    async submit() {
      const { newPassword } = this;

      const user = await Auth.currentUserPoolUser();
      await Auth.completeNewPassword(user, newPassword, {});
    },
  },
});
